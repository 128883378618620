import React, { useState } from "react";
import ReactDOM from "react-dom";
// import "semantic-ui-css/semantic.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalContext } from "./contexts/global.context";

ReactDOM.render(<GlobalContext app={<App />}></GlobalContext>, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
