// import { initializeApp } from "firebase/app";
// import firebaseConfig from "../firebase";
// const app = initializeApp(firebaseConfig);
//
const firebaseConfig = {
  apiKey: "AIzaSyA0meHFHWVWEBrQRfwwVbRGVwcLfMJQhS8",
  authDomain: "color-palette-4676b.firebaseapp.com",
  projectId: "color-palette-4676b",
  storageBucket: "color-palette-4676b.appspot.com",
  messagingSenderId: "538321883912",
  appId: "1:538321883912:web:31a42df2308ed25f0392bd",
};

export default firebaseConfig;
